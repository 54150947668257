<template>
  <div>
    <!-- 头部 -->
    <mHeader></mHeader>
    <!--  -->
    <div class="neiron">
      <div class="main">
        <!--  -->
        <div class="tit"><i class="dagou">✔</i> Order Completed</div>
        <!--  -->
        <div class="logo-box">
          <SvgIcon iconClass="logo" style="font-size: 40.5333vw"></SvgIcon>
        </div>
        <!-- 订单 资料 -->
        <div class="material">
          <div class="item">
            <span>Order No.</span>
            <p>{{ orderInfo.order_sn }}</p>
          </div>
          <div class="item">
            <span>Payment</span>
            <p>{{ orderInfo.pay_type }}</p>
          </div>
          <div class="item">
            <span>Payment Date</span>
            <p v-if="orderInfo.pay_at">
              {{ translateMonth(orderInfo.pay_at.split(" ")[0].split("-")[1]) }}
              {{ orderInfo.pay_at.split(" ")[0].split("-")[2] }},
              {{ orderInfo.pay_at.split(" ")[0].split("-")[0] }}
              {{ orderInfo.pay_at.split(" ")[1] }}
            </p>
          </div>
          <div class="item">
            <span>Contact</span>
            <p>{{ orderInfo.username || "-" }}</p>
          </div>
          <div class="item">
            <span>Email</span>
            <p>{{ orderInfo.email }}</p>
          </div>
          <div class="item2">
            <p>Moominvalley Park 1-Day Pass</p>
            <p style="margin-bottom: 18px" v-if="orderInfo.use_at">
              {{ translateMonth(orderInfo.use_at.split(" ")[0].split("-")[1]) }}
              {{ orderInfo.use_at.split(" ")[0].split("-")[2] }},
              {{ orderInfo.use_at.split(" ")[0].split("-")[0] }}
            </p>
            <div v-for="(item, index) in orderInfo.tickets" :key="index">
              <p>
                <span v-if="item.tickets_type == 1">Adult</span>
                <span v-else-if="item.tickets_type == 2">Child</span>
                <span v-else-if="item.tickets_type == 4">Secondary Student</span> x{{ item.num }}
              </p>
              <span>￥{{ setSumPrice(item.total_price, 1) }}</span>
            </div>
            <!-- <div>
              <p>Child x1</p>
              <span>￥1,800</span>
            </div> -->
          </div>
          <div class="item2 item3">
            <div>
              <p>Total</p>
              <span>￥{{ setSumPrice(totalPrices, 1) }}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- 下载 票 -->
      <div
        v-if="orderInfo.order_codes && orderInfo.order_codes.length"
        class="download-code"
      >
        <div class="download-code-tit">
          <div class="icon-box"><i class="el-icon-bottom"></i></div>
          Download Ticket
        </div>

        <!--  -->
        <div
          class="item"
          v-for="(item, index) in orderInfo.order_codes"
          :key="index"
        >
          <div class="item-left">
            No.{{ item.code.replace(/(.{4}).*(.{4})/, "$1****$2") }}
          </div>
          <div
            :class="[
              'item-right',
              { xiazai: item.status == '1' },
              { chuli: item.status != '1' },
            ]"
          >
            <el-button @click="infiniteRightBtn(item.url)">{{
              item.status == "1" ? "Download" : "Processing"
            }}</el-button>
          </div>
        </div>
        <!-- <div class="item">
          <div class="item-left">No.12******90</div>
          <div class="item-right chuli">
            <el-button v-if="'Processing'">Processing</el-button>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import mHeader from "@/components/m_header.vue";
import SvgIcon from "@/components/SvgIcon.vue";
import { get_orderInfo } from "@/api/common.js";
export default {
  data() {
    return {
      id: "",
      orderInfo: {},
      totalPrices: "", // 总价
    };
  },
  methods: {
    // 电子凭证下载
    infiniteRightBtn(url) {
      window.open(url, "_blank");
    },
    // 获取内容
    async getOrderInfoFn(id) {
      let res = await get_orderInfo(id);
      this.orderInfo = res.data.data;
      console.log(this.orderInfo);

      // 计算总价
      if (this.orderInfo.tickets.length >= 3) {
        this.totalPrices =
          +this.orderInfo.tickets[0].total_price +
          +this.orderInfo.tickets[1].total_price +
          +this.orderInfo.tickets[2].total_price;
      }else if (this.orderInfo.tickets.length >= 2) {
        this.totalPrices =
          +this.orderInfo.tickets[0].total_price +
          +this.orderInfo.tickets[1].total_price;
      } else {
        this.totalPrices = +this.orderInfo.tickets[0].total_price;
      }
    },
  },
  mounted() {
    if (this._isMobile()) {
      // 跳转至手机端路由
      if (this.$route.path != `/m_order_preview`) {
        this.$router.replace(`/m_order_preview?x=${this.id}`);
      }
    } else {
      // 跳转至 pc 端路由
      if (this.$route.path != `/order_preview`) {
        this.$router.replace(`/order_preview?x=${this.id}`);
      }
    }
  },
  created() {
    this.id = this.$route.query.x;
    console.log(this.id);

    this.getOrderInfoFn(this.id);

    localStorage.removeItem("orderParam");
    localStorage.removeItem("pasInfoForm");
  },
  components: {
    mHeader,
    SvgIcon,
  },
};
</script>

<style lang="less" scoped>
.neiron {
  padding: 16px;
  .main {
    padding: 6.4vw 5.3333vw;
    background-color: #fff;
    border-radius: 12px;
    padding-bottom: 0;

    .tit {
      font-size: 5.8667vw;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 6.4vw;
      color: #12334c;
      font-weight: bold;

      .dagou {
        display: inline-block;
        width: 4.8vw;
        height: 4.8vw;
        line-height: 4.8vw;
        border-radius: 50%;
        background-color: #12334c;
        color: #fff;
        font-size: 3.2vw;
        text-align: center;
        margin-right: 2.1333vw;
      }
    }

    .logo-box {
      text-align: center;
      height: 18.9333vw;
      margin-bottom: 6.4vw;
      .svg-icon {
        height: 100%;
      }
    }

    .material {
      .item {
        padding: 4.2667vw 0;
        border-bottom: 1px solid #b2bac144;
        // height: 20.5333vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        span {
          font-size: 3.7333vw;
          font-weight: 400;
          margin-bottom: 2.6667vw;
        }
        p {
          font-size: 4.2667vw;
          font-weight: bold;
          word-wrap: break-word;
        }
      }
      .item2 {
        padding: 4.2667vw 0;
        border-bottom: 1px solid #b2bac144;
        font-weight: 700;
        &:last-child {
          border-bottom: 0;
        }
        & > p {
          margin-bottom: 2.6667vw;
          font-size: 4.2667vw;
        }
        & > div {
          display: flex;
          justify-content: space-between;
          margin-bottom: 2.6667vw;
          font-size: 4.2667vw;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .item3 {
        padding: 5.0667vw 0;
        p {
          font-size: 4.8vw;
        }
        span {
          font-size: 5.3333vw;
        }
      }
    }
  }

  // 下载 票
  .download-code {
    margin-top: 20px;
    background-color: #fff;
    padding: 24px 20px 20px;
    padding-bottom: 0;
    border-radius: 12px;

    .download-code-tit {
      display: flex;
      font-size: 5.8667vw;
      font-weight: bold;
      align-items: center;
      margin-bottom: 4px;
      .icon-box {
        width: 4.8vw;
        height: 4.8vw;
        border-radius: 50%;
        background-color: #12334c;
        text-align: center;
        margin-right: 2.6667vw;
        font-size: 3.2vw;
        line-height: 4.8vw;
      }
      /deep/.el-icon-bottom:before {
        content: "\e6eb";
        color: #fff;
        font-weight: bold;
      }
    }

    .item {
      padding: 20px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #b2bac13b;
      &:last-child {
        border-bottom: 0;
      }

      .item-left {
        font-size: 4.2667vw;
        font-weight: bold;
      }

      .item-right {
        .el-button {
          width: 31.7333vw;
          // height: 42px;
          padding: 3.2vw 0;
          border-radius: 7px;
          background: #12334c;
          color: #fff;
          font-size: 4.2667vw;
        }

        &.chuli {
          .el-button {
            background-color: #b2bac1;
          }
        }
      }
    }
  }
}
</style>